<template>
  <div class="flex flex-col space-y-4">
    <div class="text-xl font-bold">Tentang Gki Gunung Sahari</div>
    <img
      class="rounded-lg shadow-xl object-fill object-center w-full"
      src="~@/assets/img/gerejatua.jpeg">
    <div class="text-base text-gray-800">
      <p>Sejarah GKI Gunung Sahari dimulai pada tahun 1932 dengan berdirinya Anti Opium Vereeniging (Perkumpulan Anti Pemadatan) dan WijK Verpleging (Perawatan di Wilayah) yang bertujuan menolong orang-orang yang kecanduan candu dan madat yang banyak terdapat di daerah Pasar Senen.</p><br />
      <p>Pelayanan itu melaksanakan pertemuan rutin yang kemudian berkembang kepada usaha pemberitaan Injil, sehingga pada 13 Mei 1937 lahir sebuah jemaat kecil yaitu jemaat Senen dengan pelakanaan ibadah dalam beberapa bahasa yaitu bahasa Melayu, Mandarin dan Hokkian.</p><br />
      <p>Akan tetapi perkembangan jemaat Senen mengalami kendala pada tahun 1941-1945 ketika Indonesia diduduki oleh Jepang. Puji Tuhan, hal itu kemudian berubah ketika ditahun 1946 – 1950 banyak pemuda keturunan Tionghoa hijrah ke Jakarta; dan mereka banyak mengunjungi kebaktian-kebaktian di jemaat Senen.</p><br />
      <p>Bahkan, pemuda bersama para wanita disebutkan menjadi pengiat upaya penggalangan dana pembangunan gereja lewat bazaar serta mengumpulkan koran bekas pada 3-4 Agustus 1948 yang membuat pada akhirnya Gereja Senen memiliki tempat ibadah yang tetap hingga saat ini yaitu di Laan Kadiman No.8 (sekarang bernama jalan Gunung Sahari IV).</p><br />
      <p>Selanjutnya, pada 14 Oktober 1950 nama Gereja Senen dirubah menjadi "Gereja Gunung Sahari" bersamaan dengan selesainya restorasi gedung sekaligus peresmian pemakaian gedung baru sebagai tempat kebaktian.</p><br />
      <p>Kemudian, pada 2 Desember 1958 Jemaat Gereja Gunung Sahari (Jemaat Senen) yang semula menyandang nama Tiong Hoa Kie Tok Kauw Hwee, bersama-sama jemaat-jemaat lainnya di Jawa Barat sepakat bersatu menyebut diri: Gereja Kristen Indonesia Jawa Barat. Sehingga kini jemaat Senen dikenal sampai saat ini sebagai GKI Gunung Sahari.</p><br />
    </div>
    <img
      class="rounded-lg shadow-xl object-fill object-center w-full"
      src="~@/assets/img/gerejabaru.jpg">
  </div>
</template>

<script>
export default {
  name: 'About'
}
</script>

<style scoped>

</style>
